#testimonial-slider .swiper-container {
  transition: 0.5s ease;
  width: 340px;
  padding-left: 55px;
}

#testimonial-slider .swiper-slide {
  display: flex;
  @apply space-x-10;
}

#testimonial-slider .individual-testimonial {
  width: 220px;
  flex-shrink: 0;
}

#testimonial-slider .swiper-button-next,
#testimonial-slider .swiper-button-prev {
  top: 50%;
  right: 5px;
}

@media screen and (min-width: 768px) {
  #testimonial-slider .swiper-container {
    width: 700px;
    padding-left: 55px;
  }
  #testimonial-slider .individual-testimonial {
    width: 580px;
    flex-shrink: 0;
  }
}

@media screen and (min-width: 1024px) {
  #testimonial-slider .swiper-container {
    width: 950px;
    padding-left: 100px;
  }
  #testimonial-slider .individual-testimonial {
    width: 700px;
  }
}

@media screen and (min-width: 1024px) {
  #testimonial-slider .individual-testimonial {
    width: 750px;
  }
}
