.food-menu-item {
  width: 200px;
}

.our-specialities .swiper-container {
  width: 100%;
  transition: 0.5s ease;
}

.swiper-slide {
  width: 0;
}
/* .swiper-wrapper{
    margin-left: 100px;
  } */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 70px;
  color: blue;
  background-color: #fff;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.27);
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 10px;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
  font-weight: bold;
}
.swiper-button-next {
  right: 5px;
}
.swiper-button-prev {
  left: 5px;
}
@media screen and (min-width: 320px) {
  .our-specialities .swiper-container {
    width: 240px;
    padding-left: 20px;
  }
}
@media screen and (min-width: 640px) {
  .our-specialities .swiper-container {
    width: 440px;
    padding-left: 30px;
  }
}

@media screen and (min-width: 768px) {
  .our-specialities .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 1024px) {
  .our-specialities .swiper-container {
    width: 840px;
  }
}
@media screen and (min-width: 1280px) {
  .our-specialities .swiper-container {
    width: 1040px;
    padding-left: 35px;
  }
}
