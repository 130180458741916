/* for the logout in the navbar */
.hide {
  display: none;
}
.hide:hover {
  display: block;
}
.user:hover + .hide {
  display: block;
}

/* for the charts */
/* for the line chart */
.line {
  fill: none;
  stroke: #1e44aa;
  stroke-width: 3;
}

.overlay {
  fill: none;
  pointer-events: all;
}

/* Style the dots by assigning a fill and stroke */
.dot {
  fill: #1d2b52;
  stroke: #fff;
}
.scatter-dot {
  fill: #1d2b52c4;
  stroke: #fff;
}

.focus circle {
  fill: none;
  stroke: steelblue;
}
