@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #cc8125;
  --secondary-color: #e69f48;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.min-height {
  min-height: 580px;
  /* height: 100vh; */
}
.top-60{
  top: 60%;
}
a,
button,
input {
  outline: none;
}
a:focus,
button:focus,
input:focus {
  outline: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* for the animation in the buttons */
.button-animation {
  position: relative;
  background-color: var(--primary-color);
  display: inline-block;
  color: white;
  cursor: pointer;
  overflow: hidden;
  width: auto;
}
.button-animation > .animation-text {
  z-index: 2;
  position: relative;
  display: block;
}
.button-animation > .animation-bg {
  background-color: var(--secondary-color);
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: 0.3s ease;
  height: 0;
}
.button-animation:hover > .animation-bg {
  height: 100%;
}

/* background images */

/* #home {
  background-image: url("../images/homepage/mainpage-background.jpg");
} */
#menu {
  background-image: url("../images/menu-items/menu-background.png");
  background-size: 100% 100%;
}
#service {
  background-image: url("../images/backgrounds/services.jpg");
  background-size: 100% 100%;
}
#gallery {
  background-image: url("../images/backgrounds/FullSizeRender.jpg");
  background-size: 100% 100%;
}
#contact-us {
  background-image: url("../images/contact-us/contact-us-background.jpg");
  background-size: 100% 100%;
}

#about-us {
  background-image: url("../images/backgrounds/about-us.jpg");
  background-size: 100% 100%;
}
#page-not-found {
  background-image: url("../images/404-not-found.jpg");
  background-size: 100% 100%;
}
#cart {
  background-image: url("../images/cart/shopping-cart.jpg");
  background-size: 100% 100%;
}

.position {
  position: absolute;
  top: -30px;
  right: 40%;
}
.input-style {
  @apply border w-full py-2 px-5 border-gray-300 focus:outline-none focus:border-primary text-gray-600;
}
.input-style2 {
  @apply border w-full py-2 px-5 border-gray-300 focus:outline-none focus:border-indigo-500 text-gray-600;
}

/* for login and register input fields */
.authentication-form input {
  @apply w-full py-2 px-3 border-2 border-gray-300 focus:border-gray-400;
}
.authentication-form label {
  @apply text-sm;
}
.error {
  @apply text-red-500 text-sm;
}
/* for login and register input fields */

/* for the gallery */
.gallery-item:hover .gallery-item-name {
  opacity: 1;
}
.gallery-item:hover img {
  transform: rotate(5deg) scale(1.2);
}

/* for the menu */

.menu-item:hover span {
  display: block;
}

/* for the loader */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.2s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.1s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.offer-icon {
  animation: 0.6s linear rotate infinite alternate;
}

.gallery-popup-image {
  width: 320px;
  height: 320px;
}

.order-detail-popup {
  min-width: 300px;
  max-width: 80vw;
  max-height: 80vh;
  z-index: 60;
}

@keyframes rotate {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

@media only screen and (min-width: 768px) {
  .gallery-popup-image {
    width: 400px;
    height: 400px;
  }
  .order-detail-popup {
    min-width: 600px;
  }
}

@media only screen and (min-width: 1024px) {
  .gallery-popup-image {
    width: 600px;
    height: 500px;
  }
}

/* loading animation of data  */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 70px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #cc8125;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
