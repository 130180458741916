/* width */
.menu-description::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.menu-description::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.menu-description::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.menu-description::-webkit-scrollbar-thumb:hover {
  background: #555;
}
